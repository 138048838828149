import { useEffect } from 'react';

import { useStore } from 'effector-react';
import { useLocation } from 'react-router-dom';

import { $modal } from './store/modal';
import { $tableApi } from './store/table';
import { $themeSearchListApi, $userSearchListApi } from './store/user-search-list';

export const useApp = () => {
  const modalVisible = useStore($modal);

  const location = useLocation();

  useEffect(() => {
    const body = window.document.querySelector('body');

    if (modalVisible) {
      body?.classList.add('overflow-hidden');
      body?.classList.add('fixed');
    } else {
      body?.classList.remove('fixed');
      body?.classList.remove('overflow-hidden');
    }
  }, [modalVisible]);

  useEffect(() => {
    const mathTypeDialog = window.document.querySelectorAll('.wrs_modal_dialogContainer');

    if (mathTypeDialog) for (const el of mathTypeDialog) el.classList.add('hidden');
    $userSearchListApi.reset();
    $themeSearchListApi.reset();
    $tableApi.reset();

    window.scrollTo({ top: 0 });
  }, [location.pathname]);
};
