import { createUrlQuery } from '../helpers/create-url-query';
import { ApiFetchCommonParams, ApiResponse } from '../types/api';
import { apiRequest } from './index';

export interface FetchDeleteMessageProps extends ApiFetchCommonParams {
  isFile: boolean;
}

export interface FetchUpdateMessageProps extends ApiFetchCommonParams {
  data: { message: string };
}

export interface FetchMessageCounts {
  curatorCount: number;
  homeworkCount: number;
  managerCount: number;
  onlineCuratorCount: number;
  supportCount: number;
}

export interface FetchNotification {
  count: number;
  notifications: [
    {
      adminLink: {
        id: number;
        url: string;
      };
      createdAt: string;
      id: number;
      text: string;
    }
  ];
  // managerCount: number;
  // onlineCuratorCount: number;
  // supportCount: number;
}

/** Сообщения в чате **/
export const fetchDeleteMessage = async ({ ...props }: FetchDeleteMessageProps): Promise<ApiResponse<string>> =>
  apiRequest({
    url: props.isFile ? `/message/delete-file${createUrlQuery(props)}` : `/message/delete${createUrlQuery(props)}`,
  });

export const fetchUpdateMessage = async ({ data, ...props }: FetchUpdateMessageProps): Promise<ApiResponse<string>> =>
  apiRequest({
    method: 'post',
    data: data,
    url: `/message/update${createUrlQuery(props)}`,
  });

export const fetchDownloadDocument = async ({ ...props }: { id: number | undefined }): Promise<any> =>
  apiRequest({
    url: `/documents/download-document${createUrlQuery(props)}`,
  });

/** Уведомления в боковом меню **/
export const fetchMessageCounts = async (): Promise<ApiResponse<FetchMessageCounts>> =>
  apiRequest({
    url: '/notification/count',
  });

/**Уведомления в верхнем меню(колокольчик)**/
export const fetchNotifications = async (): Promise<ApiResponse<FetchNotification>> =>
  apiRequest({
    url: '/notification/get-latest-notifications',
  });
