import { CuratorProfileTableForm } from '../components/tables/curator-profile-table/use-curator-profile-table';
import { UserCourseListTableForm } from '../components/tables/profile-course-list-table/use-profile-course-list-table';
import { UserFinanceTableForm } from '../components/tables/profile-finance-table/use-profile-finance-table';
import { createUrlQuery } from '../helpers/create-url-query';
import { ApiFetchCommonParams, ApiPaginationResponse, ApiResponse } from '../types/api';
import { TableFetchCommonParams } from '../types/api/table';
import { ManagerProfileInfo } from '../types/manager-profile/manager-profile';
import {
  FetchManagerProfileParams,
  FetchManagerUpdateParams,
  FetchPersonalManagerProfileParams,
  FetchPersonalManagerUpdateParams,
  PersonalManagerProfileInfo,
} from '../types/manager-profile/personal-manager-profile';
import {
  BlockedList,
  CompletedLesson,
  FetchConfirmedEmailParams,
  FetchConfirmInstructionPasswordParams,
  Profile,
  ProfileCuratorPupilsList,
  ProfileUpdate,
  sortRole,
} from '../types/profile';
import { TeacherInfo } from '../types/teacher';
import {
  BindSocial,
  Deduct,
  FetchBindSocialParams,
  FetchDeductParams,
  FetchDeleteRoleParams,
  FetchDeleteTagsParams,
  FetchUserInfoParams,
} from '../types/user-info/user';
import { BalanceLogProps, ProfileCourse } from '../types/user-info/user-info-type';
import { apiRequest } from './index';

export const fetchUserInfo = async (props: FetchUserInfoParams) =>
  apiRequest({ url: `/user/view${createUrlQuery(props)}` });

export const fetchProfileBalanceLog = async (
  props: TableFetchCommonParams<UserFinanceTableForm>
): Promise<ApiPaginationResponse<BalanceLogProps[]>> =>
  apiRequest({
    url: `/balance-log/index${createUrlQuery(props)}`,
  });

export const fetchProfileCourseList = async (
  props: TableFetchCommonParams<UserCourseListTableForm>
): Promise<ApiPaginationResponse<ProfileCourse[]>> =>
  apiRequest({
    url: `/user-course-profile/index${createUrlQuery(props)}`,
  });

export const fetchDeleteRole = async ({ data, ...props }: FetchDeleteRoleParams): Promise<ApiResponse<string>> =>
  apiRequest({ data, method: 'post', url: `/user-roles/remove-user-role${createUrlQuery(props)}` });

export const fetchDeleteTags = async ({ key, id }: FetchDeleteTagsParams): Promise<ApiResponse<string>> =>
  apiRequest({ errorToast: { show: false }, url: `/user-tags/remove-tag?user_id=${id}&tag=${key}` });

export const fetchUserBlockedList = async (): Promise<ApiResponse<BlockedList>> =>
  apiRequest({ errorToast: { show: false }, url: '/user-course/blocked-list' });

export const fetchUserCompletedLessons = async (): Promise<ApiResponse<CompletedLesson>> =>
  apiRequest({ errorToast: { show: false }, url: '/user-course/status-completed-lessons-list' });

export const fetchBindSocial = async ({ data, id }: FetchBindSocialParams): Promise<ApiResponse<BindSocial>> =>
  apiRequest({ data, method: 'post', url: `/user/binding-account?user_id=${id}` });

export const fetchUnBindSocial = async ({ id }: ApiFetchCommonParams): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/user/drop-account?user_id=${id}` });

export const fetchDeduct = async ({ data, ...props }: FetchDeductParams): Promise<ApiResponse<Deduct>> =>
  apiRequest({ data, method: 'post', url: `/user/deduct${createUrlQuery(props)}` });

export const fetchPersonalManagerProfile = (
  props: FetchPersonalManagerProfileParams
): Promise<ApiResponse<PersonalManagerProfileInfo>> =>
  apiRequest({ url: `/user/manager-profile${createUrlQuery(props)}` });

export const fetchPersonalManagerProfileUpdate = async ({
  data,
  ...props
}: FetchPersonalManagerUpdateParams): Promise<ApiResponse<string>> =>
  apiRequest({ data, method: 'post', url: `/user/manager-update${createUrlQuery(props)}` });

export const fetchRokInfo = (
  props: ApiFetchCommonParams
): Promise<
  ApiResponse<{
    exam: { exam: number; examText: string; id: number }[];
    subject: { id: number; subject: number; subjectText: string }[];
    user: TeacherInfo;
  }>
> => apiRequest({ url: `/user/senior-curator-profile${createUrlQuery(props)}` });

export const fetchRdkInfo = (
  props: ApiFetchCommonParams
): Promise<
  ApiResponse<{
    exam: { exam: number; examText: string; id: number }[];
    subject: { id: number; subject: number; subjectText: string }[];
    user: TeacherInfo;
  }>
> => apiRequest({ url: `/user/rdk-profile${createUrlQuery(props)}` });

export const fetchRopmInfo = (
  props: ApiFetchCommonParams
): Promise<
  ApiResponse<{
    exam: { exam: number; examText: string; id: number }[];
    subject: { id: number; subject: number; subjectText: string }[];
    user: TeacherInfo;
  }>
> => apiRequest({ url: `/user/ropm-profile${createUrlQuery(props)}` });

export const fetchOnlineCuratorInfo = (
  props: ApiFetchCommonParams
): Promise<
  ApiResponse<{
    exam: { exam: number; examText: string; id: number }[];
    subject: { id: number; subject: number; subjectText: string }[];
    user: TeacherInfo;
  }>
> => apiRequest({ url: `/user/online-curator-profile${createUrlQuery(props)}` });

export const fetchManagerProfileInfo = (props: FetchManagerProfileParams): Promise<ApiResponse<ManagerProfileInfo>> =>
  apiRequest({ url: `/user/manager-user-profile${createUrlQuery(props)}` });

export const fetchManagerProfileUpdate = async ({
  data,
  ...props
}: FetchManagerUpdateParams): Promise<ApiResponse<string>> =>
  apiRequest({ data, method: 'post', url: `/user/manager-user-update${createUrlQuery(props)}` });

export const fetchPhoneConfirmed = async (props: FetchConfirmedEmailParams): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/user/set-phone-confirmed${createUrlQuery(props)}` });

export const fetchEmailConfirmed = async (props: FetchConfirmedEmailParams): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/user/set-email-confirmed${createUrlQuery(props)}` });

export const fetchInstructionPassword = async (
  props: FetchConfirmInstructionPasswordParams
): Promise<ApiResponse<string>> => apiRequest({ url: `/user/send-recovery${createUrlQuery(props)}` });

export const fetchProfileInfoUpdate = async ({ data, ...props }: ProfileUpdate): Promise<ApiResponse<Profile>> =>
  apiRequest({ data, errorToast: { show: false }, method: 'post', url: `/profile/update${createUrlQuery(props)}` });

export const fetchUserInfoUpdate = async ({ data, ...props }: ProfileUpdate): Promise<ApiResponse<Profile>> =>
  apiRequest({ data, errorToast: { show: false }, method: 'post', url: `/user/update${createUrlQuery(props)}` });

export const fetchProfileDeleteCourse = async (props: { id: number }): Promise<ApiResponse<string>> =>
  apiRequest({ url: `/user-course/delete${createUrlQuery(props)}` });

export const fetchDeleteExam = async ({ url }: { url: string }): Promise<ApiResponse<string>> =>
  apiRequest({ url: `${url}` });

export const fetchDeleteSubject = async ({ url }: { url: string }): Promise<ApiResponse<string>> =>
  apiRequest({ url: `${url}` });

export const fetchProfileCuratorPupilsList = async (
  props: TableFetchCommonParams<CuratorProfileTableForm> & { curatorId: string | undefined }
): Promise<ApiPaginationResponse<ProfileCuratorPupilsList[]>> =>
  apiRequest({
    url: `/user-curator/curator-profile-pupils${createUrlQuery(props)}`,
  });

export const fetchProfileUniqueRoleList = async (props: { id: string | undefined }): Promise<ApiResponse<sortRole[]>> =>
  apiRequest({ url: `/user/list-roles-for-select${createUrlQuery(props)}` });
