import { createApi, createStore } from 'effector';

export type ModalNames =
  | 'profile-payment'
  | 'transfer-curator'
  | 'transfer-manager'
  | 'change-exam'
  | 'add-tags'
  | 'add-roles'
  | 'bind-social'
  | 'activate-course'
  | 'create-theme-bank'
  | 'create-task-group'
  | 'create-theme-text-bank'
  | 'create-group-bank'
  | 'update-theme-bank'
  | 'update-theme-text-bank'
  | 'update-group-bank'
  | 'create-reviews'
  | 'create-theme-knowledge'
  | 'update-theme-knowledge'
  | 'add-subject'
  | 'invoice-update-subscription'
  | 'invoice-update'
  | 'add-exam'
  | 'add-manager-invoice'
  | 'curator-connect-create'
  | 'curator-connect-edit'
  | 'personal-manager-connect-create'
  | 'personal-manager-connect-edit'
  | 'ropm-curator-connect-create'
  | 'ropm-curator-connect-edit'
  | 'rop-connect-create'
  | 'rop-connect-edit'
  | 'create-user-tag'
  | 'update-user-tag'
  | 'create-question-tag'
  | 'profile-change-curator-connect'
  | 'webinar-link'
  | 'add-ask-tags'
  | 'add-question-test'
  | 'profile-change-manager-connect'
  | 'form-dashboard'
  | 'update-multi-task'
  | 'create-multi-task'
  | 'choose-multi-task-question-number'
  | 'create-permanent-promocode'
  | 'create-temporary-promocode'
  | 'create-one-time-promocode'
  | 'update-one-time-promocode'
  | 'button-support-message'
  | 'create-welcome-message'
  | 'create-first-button-support-robot'
  | 'create-message'
  | 'edit-welcome-message'
  | 'delete-welcome-message';

export const $modal = createStore<ModalNames | null>(null);

export const $modalApi = createApi($modal, {
  hide: () => null,
  show: (_, name: ModalNames) => name,
});
