import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { CustomInputProps } from '../../custom-input/custom-input';

interface InputErrorProps extends Pick<CustomInputProps, 'errorClassName'> {
  name: string;
}

export const InputError = ({ name, errorClassName }: InputErrorProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const error = errors[name]?.message as string;

  if (!error) return null;

  return <div className={cn('text-xs text-red', errorClassName)}>{error}</div>;
};
